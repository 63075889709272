import React from 'react';
import logo from './logo.svg';
import './App.css';
import { QueryClientProvider, QueryClient, useQuery } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import axios from "axios";
import Landing from './Landing';

// Create a client
const queryClient = new QueryClient()

function App() {



  return (
    <QueryClientProvider client={queryClient}>
      <Landing />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>

  );
}

export default App;

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import logo from './img/Keyfactor-logo-white-leftspace-2021.png';

const theme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#390c72'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: "#28B905"
        }
      }
    }
  }
});

export default function Landing() {

  const { isLoading, error, data, isFetching } = useQuery(["repoData"], () =>
  axios
    .get("/api/session")
    .then((res) => res.data)
  );
  console.log(data?.account?.accountId);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
          <Box
            component="img"
            src={logo}
            alt="Logo"
            sx={{ width: 150 }}
          />
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            {isLoading ? (
              <Typography variant="h5" align="center" color="text.secondary" paragraph>
                Loading...
              </Typography> 
            )
                : data?.error ? (
<>
                                    <Typography
                                    component="h1"
                                    variant="h3"
                                    align="center"
                                    color="text.primary"
                                    gutterBottom
                                  >
                                    Sorry!
                                  </Typography>
                                  <Typography variant="h5" align="center" color="text.secondary" paragraph>
                                  We've encountered an error. Please contact your sales representative for assistance.
                                  </Typography>
                                  <Stack
                                    sx={{ pt: 4 }}
                                    direction="row"
                                    spacing={2}
                                    justifyContent="center"
                                  >
                                    {/* <Button variant="contained">Logout</Button> */}
                                  </Stack>
                                  </>

                ) : !data?.account?.accountId ? (
                  <>

                <Typography variant="h5" align="center" color="text.secondary" paragraph>
                Your session has expired. Please contact your sales representative if you have any questions about your subscription.
                </Typography>
                <Stack
                  sx={{ pt: 4 }}
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                >
                  
                </Stack>
                
                </>  ): (
                                    <>
                                    <Typography
                                    component="h1"
                                    variant="h3"
                                    align="center"
                                    color="text.primary"
                                    gutterBottom
                                  >
                                    Thank You!
                                  </Typography>
                                  <Typography variant="h5" align="center" color="text.secondary" paragraph>
                                  Your account has been approved. Please contact your sales representative to let them know you have accepted the offer.
                                  </Typography>
                                  <Typography variant="h6" align="center" color="text.primary" paragraph>
                                  Account ID: {data?.account?.accountId}
                                  </Typography>
                                  <Stack
                                    sx={{ pt: 4 }}
                                    direction="row"
                                    spacing={2}
                                    justifyContent="center"
                                  >
                                    {/* <Button variant="contained">Logout</Button> */}
                                  </Stack>
                                  </>
                )             
              }

          </Container>
        </Box>
        
      </main>

    </ThemeProvider>
  );
}
